import React, { forwardRef } from "react";
/* Ant Design */
import { Select as AntSelect } from "antd";
import { useTheme } from "hooks";

/* Styles */
if (typeof window === "object") {
  require("./style.scss");
}

const Select = (
  {
    className = "",
    type = "primary",
    big = false,
    noPadding = false,
    modalStyle = false,
    ...antSelectProps
  },
  ref
) => {
  const { theme } = useTheme();
  const selectElements = document.querySelectorAll(".ant-select-selection");
  const selectArrowElements = document.querySelectorAll(".ant-select-arrow");
  const selectedValueElements = document.querySelectorAll(
    ".ant-select-selection-selected-value"
  );
  selectElements.forEach((element) => {
    element.style.border = `1px solid #${theme.actionColor}`;
  });
  selectArrowElements.forEach((element) => {
    element.style.color = `#${theme.actionColor}`;
  });
  selectArrowElements.forEach((element) => {
    element.style.color = `#${theme.actionColor}`;
  });
  selectedValueElements.forEach((element) => {
    element.style.color = `#${theme.actionColor}`;
  });

  return (
    <AntSelect
      ref={ref}
      className={
        modalStyle
          ? ""
          : `select-fleks-ui ${className || ""} ${type}${big ? " big" : ""}${
              noPadding ? " no-padding" : ""
            }`
      }
      optionFilterProp="children"
      maxTagCount={2}
      {...antSelectProps}
    />
  );
};

Select.Option = AntSelect.Option;
export default forwardRef(Select);
