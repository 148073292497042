import React from "react";
/* Ant Design */
import { Tooltip as AntTooltip } from "antd";
/* Custom Hooks */
import { useTheme } from "hooks";

/* Style */
if (typeof window === "object") {
  // @ts-ignore
  require("./style.scss");
}

/**
 * @param {import("antd/lib/tooltip").TooltipProps} props
 */
function Tooltip({ className = "", ...tooltipProps }) {
  const { theme } = useTheme();

  return (
    <AntTooltip
      {...tooltipProps}
      placement="bottomRight"
      arrowPointAtCenter
      overlayClassName={`fleks-ui-tooltip primaryUI ${className}`}
      overlayStyle={{ color: `#${theme.actionColor}` }}
      align={{ offset: [0, 0] }}
    />
  );
}

export default Tooltip;
