import axios from "axios";
import { GIPHY_API_KEY } from "../config";

const GIPHY_BASE_URL = "https://api.giphy.com/v1/";

class GiphyService {
  static getRandomGIF({ tag = "" }) {
    return axios
      .get(
        `${GIPHY_BASE_URL}gifs/random?api_key=${GIPHY_API_KEY}&${
          tag ? "tag=" + tag : ""
        }`
      )
      .then(response => {
        return response.data.data;
      });
  }
}

export default GiphyService;
