import { combineReducers } from "redux";
import { SET_SCHEDULER_PREFERENCES, USER_LOGIN } from "redux/types";

const showHours = (state = true, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.scheduler &&
        action.data.preferences.scheduler.card &&
        typeof action.data.preferences.scheduler.card.showHours === "boolean"
        ? action.data.preferences.scheduler.card.showHours
        : state;

    default:
      return state;
  }
};

const showCategory = (state = true, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.scheduler &&
        action.data.preferences.scheduler.card &&
        typeof action.data.preferences.scheduler.card.showCategory === "boolean"
        ? action.data.preferences.scheduler.card.showCategory
        : state;

    default:
      return state;
  }
};

const showProject = (state = false, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.scheduler &&
        action.data.preferences.scheduler.card &&
        typeof action.data.preferences.scheduler.card.showProject === "boolean"
        ? action.data.preferences.scheduler.card.showProject
        : state;

    case SET_SCHEDULER_PREFERENCES:
      return action.data.card &&
        typeof action.data.card.showProject === "boolean"
        ? action.data.card.showProject
        : state;

    default:
      return state;
  }
};

const showClient = (state = false, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.scheduler &&
        action.data.preferences.scheduler.card &&
        typeof action.data.preferences.scheduler.card.showClient === "boolean"
        ? action.data.preferences.scheduler.card.showClient
        : state;

    case SET_SCHEDULER_PREFERENCES:
      return action.data.card &&
        typeof action.data.card.showClient === "boolean"
        ? action.data.card.showClient
        : state;

    default:
      return state;
  }
};

const showCity = (state = false, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.scheduler &&
        action.data.preferences.scheduler.card &&
        typeof action.data.preferences.scheduler.card.showCity === "boolean"
        ? action.data.preferences.scheduler.card.showCity
        : state;

    case SET_SCHEDULER_PREFERENCES:
      return action.data.card && typeof action.data.card.showCity === "boolean"
        ? action.data.card.showCity
        : state;

    default:
      return state;
  }
};

const preferences = combineReducers({
  showHours,
  showCategory,
  showClient,
  showProject,
  showCity,
});

export default preferences;
