import { SET_EXTENSIVE_FILTERS } from "redux/types";
import { combineReducers } from "redux";

const show = (state = false, action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.show !== "undefined" ? action.data.show : state;

    default:
      return state;
  }
};

const reloadCandidates = (state = false, action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.reloadCandidates !== "undefined"
        ? action.data.reloadCandidates
        : state;

    default:
      return state;
  }
};

const isQualified = (state = true, action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.isQualified !== "undefined"
        ? action.data.isQualified
        : state;

    default:
      return state;
  }
};

const fullName = (state = "", action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.fullName !== "undefined"
        ? action.data.fullName
        : state;

    default:
      return state;
  }
};

const fullNameExternal = (state = "", action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.fullNameExternal !== "undefined"
        ? action.data.fullNameExternal
        : state;

    default:
      return state;
  }
};

const categories = (state = "", action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.categories !== "undefined"
        ? action.data.categories
        : state;

    default:
      return state;
  }
};

const categoriesUuids = (state = "", action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.categoriesUuids !== "undefined"
        ? action.data.categoriesUuids
        : state;

    default:
      return state;
  }
};

const lastContactedAt = (state = "", action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.lastContactedAt !== "undefined"
        ? action.data.lastContactedAt
        : state;

    default:
      return state;
  }
};

const distance = (state = "", action = {}) => {
  switch (action.type) {
    case SET_EXTENSIVE_FILTERS:
      return typeof action.data.distance !== "undefined"
        ? action.data.distance
        : state;

    default:
      return state;
  }
};

const extensive = combineReducers({
  show,
  reloadCandidates,
  isQualified,
  fullName,
  fullNameExternal,
  categories,
  categoriesUuids,
  lastContactedAt,
  distance
});

export default extensive;
