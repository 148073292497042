/* eslint-disable */
import { googleDriveFilePicker } from "../config";

class GoogleSheetsService {
  constructor() {
    this.config = {
      apiKey: googleDriveFilePicker.developerKey,
      clientId: googleDriveFilePicker.clientId,
      discoveryDocs: [
        "https://sheets.googleapis.com/$discovery/rest?version=v4",
      ],
      scope: "https://www.googleapis.com/auth/spreadsheets",
    };
  }

  /**
   * Add a event listener to when the sign-in state change
   * @param {Function} callback
   */
  onSignedChange(callback) {
    load(this.config).then(() =>
      gapi.auth2
        .getAuthInstance()
        .isSignedIn.listen((isSignedIn) => callback(isSignedIn))
    );
  }

  /**
   * Reads a google sheets
   * @param {String} spreadsheetId *Required
   * @param {Object} options optional
   *   initialCell: string - Cell where selection start. default: A
   *   finalCell: string - Cell where selection end. default: Z
   *   parser: func - Function to parser the response, is optional
   *   sheetName: string - Sheet name where must be select
   *   fields: string[] - Field that use the default parser to build objects
   */
  getData(spreadsheetId, options = {}) {
    const range = getRange(
      options.sheetName,
      options.initialCell,
      options.finalCell
    );

    return initClient(this.config).then(() =>
      gapi.client.sheets.spreadsheets.values
        .get({
          range,
          spreadsheetId,
        })
        .then(
          (response) =>
            options.parser
              ? options.parser(response.result)
              : defaultParser(response.result, options.fields),
          (response) => response.result
        )
    );
  }
}

/**
 * Inits the google APIs client with Google Sheets Config
 */
const initClient = (config) => {
  return new Promise((resolve, reject) => {
    load(config).then(() => {
      // Check out if exits a active session
      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        resolve(true);
      } else {
        gapi.auth2.getAuthInstance().signIn();
      }

      // Adds an event listener to resolve the promise when the user sign-in
      gapi.auth2.getAuthInstance().isSignedIn.listen((isSignedIn) => {
        if (!isSignedIn) reject();

        resolve(isSignedIn);
      });
    });
  });
};

/**
 * Loads the initial config to use google sheets API
 * @param {Object} config
 */
const load = (config) => {
  return new Promise((resolve, reject) => {
    gapi.load("client:auth2", () => {
      gapi.client.init(config).then(() => resolve());
    });
  });
};

/* Private Methods */
/**
 * Constructs the range
 * @param {String} sheetName
 * @param {String} init must be A1, AB1, C12, etc.
 * @param {String} final must be A1, AB1, C12, etc.
 */
const getRange = (sheetName, init, final) => {
  init = init && init.toUpperCase();
  final = final && final.toUpperCase();

  const hasInit = init && /^[A-Z]+[1-9]+$/g.test(init);
  const hasFinal = final && /^[A-Z]+[1-9]+$/g.test(init);

  return `${sheetName ? `'${sheetName}'!` : ""}${hasInit ? init : "A"}:${
    hasFinal ? final : "AZ"
  }`;
};

/**
 * Parsers the array result to correct objects
 * @param {Object} result
 * @param {Array} fields to add in parsered object
 */
const defaultParser = (result, fields) => {
  const { values } = result;
  const numRows = values.length;
  const parsedData = [];
  let base;

  for (let i = 1; i < numRows; i++) {
    base = {};

    values[0].map((item, j) => {
      let fieldName = transformToLowerCase(item);

      if (fields && fields.indexOf(fieldName) === -1) return;

      base = { ...base, [fieldName]: values[i][j] };
    });

    parsedData.push(base);
  }

  return parsedData;
};

/**
 * Transforms the strings
 * @param {String} str
 */
const transformToLowerCase = (str) => {
  return `${str}`
    .split("")
    .map((letter) => (letter === " " ? "_" : letter.toLocaleLowerCase()))
    .join("");
};

export default new GoogleSheetsService();
