import {
  USER_LOGIN,
  SET_DATABASE_TABLE_VALUES,
  SET_DATABASE_TABLE_FILTERS,
  SET_DATABASE_TABLE_APPLIED_FILTERS,
} from "redux/types";
import { combineReducers } from "redux";
import moment from "moment";

const INITIAL_FILTERS = {
  startDate: moment()
    .startOf("month")
    .format("DD-MM-YYYY"),
  endDate: moment()
    .endOf("month")
    .format("DD-MM-YYYY"),
  minNumberTimes: 1,
};

const columns = (
  state = [
    "full_name",
    "number_job_applications",
    "total_users_hours_worked",
    "total_applicable_travel_time",
    "total_compensation_hours",
    "total_gross_salary",
    "total_travel_distance",
    "total_other_cost",
  ],
  action = {}
) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.totals !== "undefined"
        ? action.data.preferences.database.totals.columns
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" && action.data.columns
        ? action.data.columns
        : state;

    default:
      return state;
  }
};

const selectedTab = (state = "open", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.totals !== "undefined" &&
        action.data.preferences.database.totals.selectedTab !== "incompleted"
        ? action.data.preferences.database.totals.selectedTab
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" && action.data.tab
        ? action.data.tab
        : state;

    default:
      return state;
  }
};

const page = (state = 1, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.totals !== "undefined"
        ? action.data.preferences.database.totals.page
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" && action.data.page
        ? action.data.page
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return 1;

    default:
      return state;
  }
};

const size = (state = 25, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.totals !== "undefined" &&
        action.data.preferences.database.totals.size <= 100
        ? action.data.preferences.database.totals.size
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" && action.data.size
        ? action.data.size
        : state;

    default:
      return state;
  }
};

const sortKey = (state = "first_name", action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" && action.data.sortKey
        ? action.data.sortKey
        : state;

    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.totals !== "undefined"
        ? action.data.preferences.database.totals.sortKey
        : state;


    default:
      return state;
  }
};

const filters = (state = INITIAL_FILTERS, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_FILTERS:
      return action.data.table === "totals"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "totals"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = INITIAL_FILTERS, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "totals"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const selectedItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" && action.data.items
        ? action.data.items
        : state;

    default:
      return state;
  }
};

const selectedFullItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" && action.data.fullItems
        ? action.data.fullItems
        : state;

    default:
      return state;
  }
};

const reset = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" &&
        typeof action.data.reset === "boolean"
        ? action.data.reset
        : state;

    default:
      return state;
  }
};

const allListSelected = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" &&
        typeof action.data.allListSelected === "boolean"
        ? action.data.allListSelected
        : state;

    default:
      return state;
  }
};

const total = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" &&
        typeof action.data.total === "number"
        ? action.data.total
        : state;

    default:
      return state;
  }
};

const listInternalPerEmployee = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "totals" &&
        typeof action.data.listInternalPerEmployee === "boolean"
        ? action.data.listInternalPerEmployee
        : state;

    default:
      return state;
  }
};

export const totals = combineReducers({
  columns,
  selectedTab,
  size,
  page,
  sortKey,
  filters,
  appliedFilters,
  selectedItems,
  selectedFullItems,
  reset,
  allListSelected,
  total,
  listInternalPerEmployee,
});

export default totals;
