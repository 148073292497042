import {
  USER_LOGIN,
  SET_DATABASE_TABLE_VALUES,
  SET_DATABASE_TABLE_FILTERS,
  SET_DATABASE_TABLE_APPLIED_FILTERS
} from "redux/types";
import { combineReducers } from "redux";

const columns = (
  state = [
    "name",
    "client_name",
    "phone_number",
    "project_name",
    "city",
    "time",
    "start_date",
    "workflow_phase",
    "status"
  ],
  action = {}
) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.shifts !== "undefined"
        ? action.data.preferences.database.shifts.columns
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" && action.data.columns
        ? action.data.columns
        : state;

    default:
      return state;
  }
};

const selectedTab = (state = "upcoming", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.shifts !== "undefined"
        ? action.data.preferences.database.shifts.selectedTab
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" && action.data.tab
        ? action.data.tab
        : state;

    default:
      return state;
  }
};

const page = (state = 1, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.shifts !== "undefined"
        ? action.data.preferences.database.shifts.page
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" && action.data.page
        ? action.data.page
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return 1;

    default:
      return state;
  }
};

const size = (state = 25, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.shifts !== "undefined" &&
        action.data.preferences.database.shifts.size <= 100
        ? action.data.preferences.database.shifts.size
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" && action.data.size
        ? action.data.size
        : state;

    default:
      return state;
  }
};

const sortKey = (state = "start_date", action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" && action.data.sortKey
        ? action.data.sortKey
        : state;

    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.shifts !== "undefined"
        ? action.data.preferences.database.shifts.sortKey
        : state;

    default:
      return state;
  }
};

const filters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_FILTERS:
      return action.data.table === "shifts"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "shifts"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "shifts"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const selectedItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" && action.data.items
        ? action.data.items
        : state;

    default:
      return state;
  }
};

const selectedFullItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" && action.data.fullItems
        ? action.data.fullItems
        : state;

    default:
      return state;
  }
};

const reset = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" &&
        typeof action.data.reset === "boolean"
        ? action.data.reset
        : state;

    default:
      return state;
  }
};

const listFreelancers = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" &&
        typeof action.data.listFreelancers === "boolean"
        ? action.data.listFreelancers
        : state;

    default:
      return state;
  }
};

const allListSelected = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" &&
        typeof action.data.allListSelected === "boolean"
        ? action.data.allListSelected
        : state;

    default:
      return state;
  }
};

const total = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "shifts" &&
        typeof action.data.total === "number"
        ? action.data.total
        : state;

    default:
      return state;
  }
};

export const shifts = combineReducers({
  columns,
  selectedTab,
  size,
  page,
  sortKey,
  filters,
  appliedFilters,
  selectedItems,
  selectedFullItems,
  reset,
  allListSelected,
  total,
  listFreelancers
});

export default shifts;
