import React from "react";

const DatabaseContext = React.createContext({
  isArchive: false,
  isAdmin: undefined,
  isMarketplace: false,
  isMarketplaceEmployer: false,
  isMarketplaceAdmin: false,
  hasMarketplaceConnection: null
  //jobInvalidStates: null,
});

export default DatabaseContext;
