import axios from "axios";
import { serverConfig } from "../config";

const apiUrl = serverConfig.apiUrl;

const availabilityService = {
  createDaySet: (token, employeeUuid, payload) =>
    axios.post(
      `${apiUrl}/api/availabilities/daysets/`,
      { employee: employeeUuid, ...payload },
      {
        headers: { Authorization: `JWT ${token}` }
      }
    )
};

export default availabilityService;
