import axios from "axios";
import { serverConfig } from "../config";

const apiUrl = serverConfig.apiUrl;

const appointmentService = {
  getAppointment: (token, tenantUuid) => {
    return axios.get(
      `${apiUrl}/api/appointments/${tenantUuid}/?is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  createAppointment: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/appointments/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` }
    });
  },
  updateAppointment: (token, tenantUuid, appointmentUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/appointments/${tenantUuid}/${appointmentUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  }
};

export default appointmentService;
