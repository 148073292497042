import { combineReducers } from "redux";
import {
  SET_UI_VARS,
  SET_COLORS,
  USER_LOGIN,
  SHOW_COMMENTS_DRAWER,
  SHOW_COMMENTS_SIDEBAR,
  SHOW_NOTIFICATION_MODAL,
  SHOW_DRAWER_WITH_SLOTS,
  TOGGLE_DRAWER_LOADING,
  SET_SAVING_PREFERENCES,
  SET_DISABLE_KEY_CALLBACK,
  TOGGLE_EXPAND_TABLE_ITEMS,
  SET_WORKFLOW_DETAIL_RESPONSE
} from "redux/types";

const wVars = (
  state = { wWidth: 1024, wHeight: 768, loaded: false },
  action = {}
) => {
  switch (action.type) {
    case SET_UI_VARS:
      return {
        wWidth: action.width,
        wHeight: action.height,
        isMobile: action.isMobile,
        loaded: true
      };

    default:
      return state;
  }
};

const colors = (state = null, action) => {
  switch (action.type) {
    case USER_LOGIN:
      let colors = Object.assign({}, state);
      if (action.data && action.data.tenant) {
        if (action.data.tenant.action_color) {
          //Action
          colors.action = action.data.tenant.action_color;
        }
        if (action.data.tenant.primary_color) {
          //Primary
          colors.primary = action.data.tenant.primary_color;
        }
        if (action.data.tenant.secondary_color) {
          //Secondary
          colors.secondary = action.data.tenant.secondary_color;
        }
      }
      if (Object.keys(colors).length === 0) {
        colors = null;
      }
      return colors;

    case SET_COLORS:
      return action.colors; //Primary, secondary, action

    default:
      return state;
  }
};

const commentsDrawer = (
  state = {
    showDrawer: false,
    uuid: false,
    extraInfoUuid: "",
    sectionList: [],
    loading: false
  },
  action = {}
) => {
  switch (action.type) {
    case SHOW_COMMENTS_DRAWER:
      return {
        ...state,
        showDrawer:
          (!!action.uuid || !!action.extraInfoUuid) && action.showDrawer,
        uuid: action.uuid,
        extraInfoUuid: action.extraInfoUuid,
        sectionList: action.slots || []
      };

    case SHOW_DRAWER_WITH_SLOTS:
      return {
        ...state,
        showDrawer: action.show,
        sectionList: action.slots || []
      };

    case TOGGLE_DRAWER_LOADING:
      return {
        ...state,
        loading: action.loading,
        showDrawer: action.show
      };

    default:
      return state;
  }
};

const commentsSidebar = (
  state = {
    showSidebar: false,
    uuid: ""
  },
  action = {}
) => {
  switch (action.type) {
    case SHOW_COMMENTS_SIDEBAR:
      return {
        ...state,
        showSidebar: !!action.data.uuid && action.data.showSidebar,
        uuid: action.uuid
      };

    default:
      return state;
  }
};

const notificationModal = (
  state = {
    showModal: false,
    subject: false,
    data: false,
    getDataSource: false,
    config: false
  },
  action = {}
) => {
  switch (action.type) {
    case SHOW_NOTIFICATION_MODAL:
      return {
        showModal: action.visibility,
        subject: action.subject,
        data: action.data,
        getDataSource: action.getDataSource,
        config: action.config
      };

    default:
      return state;
  }
};

const savingPreferences = (state = false, action = {}) => {
  switch (action.type) {
    case SET_SAVING_PREFERENCES:
      return action.data;

    default:
      return state;
  }
};

const disableKeyCallback = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DISABLE_KEY_CALLBACK:
      return typeof action.data.disable === "boolean"
        ? action.data.disable
        : state;

    default:
      return state;
  }
};

const expandTableItems = (state = false, action = {}) => {
  switch (action.type) {
    case TOGGLE_EXPAND_TABLE_ITEMS:
      return !action.data
    default:
      return state;
  }
};

const workflowDetailResponse = (state = null, action ={}) => {
  switch (action.type){
    case SET_WORKFLOW_DETAIL_RESPONSE:
      return action.data;
    default:
      return state;
  }
}

const ui = combineReducers({
  wVars,
  colors,
  commentsDrawer,
  commentsSidebar,
  notificationModal,
  savingPreferences,
  disableKeyCallback,
  expandTableItems,
  workflowDetailResponse
});

export default ui;
