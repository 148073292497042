import {
  USER_LOGIN,
  SET_DATABASE_TABLE_VALUES,
  SET_DATABASE_TABLE_FILTERS,
  SET_DATABASE_TABLE_APPLIED_FILTERS,
} from "redux/types";
import { combineReducers } from "redux";

const columns = (
  state = [
    "title",
    "occupation",
    "city",
    "zip_code",
    "address",
    "times",
    "date",
    "client",
    "job_category",
    "status",
  ],
  action = {}
) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.jobs !== "undefined"
        ? action.data.preferences.database.jobs.columns
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" && action.data.columns
        ? action.data.columns
        : state;

    default:
      return state;
  }
};

const selectedTab = (state = "all", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.jobs !== "undefined"
        ? action.data.preferences.database.jobs.selectedTab
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" && action.data.tab
        ? action.data.tab
        : state;

    default:
      return state;
  }
};

const page = (state = 1, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.jobs !== "undefined"
        ? action.data.preferences.database.jobs.page
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" && action.data.page
        ? action.data.page
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return 1;

    default:
      return state;
  }
};

const size = (state = 25, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.jobs !== "undefined" &&
        action.data.preferences.database.jobs.size <= 100
        ? action.data.preferences.database.jobs.size
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" && action.data.size
        ? action.data.size
        : state;

    default:
      return state;
  }
};

const sortKey = (state = "start_date", action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" && action.data.sortKey
        ? action.data.sortKey
        : state;

    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.jobs !== "undefined"
        ? action.data.preferences.database.jobs.sortKey
        : state;

    default:
      return state;
  }
};

const filters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_FILTERS:
      return action.data.table === "jobs"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "jobs"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "jobs"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const selectedItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" && action.data.items
        ? action.data.items
        : state;

    default:
      return state;
  }
};

const selectedFullItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" && action.data.fullItems
        ? action.data.fullItems
        : state;

    default:
      return state;
  }
};

const reset = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" &&
        typeof action.data.reset === "boolean"
        ? action.data.reset
        : state;

    default:
      return state;
  }
};

const allListSelected = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" &&
        typeof action.data.allListSelected === "boolean"
        ? action.data.allListSelected
        : state;

    default:
      return state;
  }
};

const total = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" &&
        typeof action.data.total === "number"
        ? action.data.total
        : state;

    default:
      return state;
  }
};

const listJobRequests = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "jobs" &&
        typeof action.data.listJobRequests === "boolean"
        ? action.data.listJobRequests
        : state;

    default:
      return state;
  }
};

export const jobs = combineReducers({
  columns,
  selectedTab,
  size,
  page,
  sortKey,
  filters,
  appliedFilters,
  selectedItems,
  selectedFullItems,
  reset,
  allListSelected,
  total,
  listJobRequests,
});

export default jobs;
