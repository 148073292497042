import { SET_SUBMIT_DETAILS, SET_IS_SAVING } from "redux/types";

const projects = (
  state = { submitDetails: false, isSaving: false },
  action = {}
) => {
  switch (action.type) {
    case SET_SUBMIT_DETAILS:
      return { submitDetails: action.value };

    case SET_IS_SAVING:
      return { isSaving: action.value };

    default:
      return state;
  }
};

export default projects;
