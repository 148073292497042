import axios from "axios";
import { serverConfig } from "../config";
/* Utils */
import { getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl;

class PlannerService {
  static axiosInstance = axios.create({
    baseURL: `${apiUrl}/api`,
  });

  static getJobWithRange(token, tenantUuid, isDashboard, startDate, endDate, options = {}, page= 1, pageSize=10000) {
    const {
      filters = {},
      clusters = false,
      grouped = false,
      includeShared = false,
    } = options;
    let BASE_URL = `/jobs/subjob${
      grouped ? "/grouped" : ""
    }/${tenantUuid}/${startDate}/${endDate}/?${
      clusters ? "cluster=True" : ""
    }${getFiltersString(filters)}&page=${page}&page_size=${pageSize}`;

    if(isDashboard){
      BASE_URL = `${BASE_URL}&applied_users__gte=1`
    }

    return this.axiosInstance
      .get(BASE_URL, {
        headers: { Authorization: `JWT ${token}` },
      })
      .then((response) => {
        return response.data.results;
      })
      .then((ownJobs) => {
        if (includeShared) {
          return this.getSharedJobs(token, tenantUuid, startDate, endDate, {
            clusters,
            filters,
          }).then((sharedJobs) => {
            return [...ownJobs, ...sharedJobs];
          });
        }

        return ownJobs;
      });
  }

  static getSharedJobs(token, tenantUuid, startDate, endDate, options = {}) {
    const { filters = {}, clusters = false, grouped = true } = options;
    const BASE_URL = `/jobs/shared-job${
      grouped ? "/grouped" : ""
    }/${tenantUuid}/${startDate}/${endDate}/?status=accepted&${
      clusters ? "cluster=True" : ""
    }${getFiltersString(filters)}`;

    return this.axiosInstance
      .get(BASE_URL, {
        headers: { Authorization: `JWT ${token}` },
      })
      .then((response) => {
        return response.data.results;
      });
  }

  static getStats(token, fromDate, toDate) {
    return this.axiosInstance
      .get(
        `${apiUrl}/api/jobs/scheduler/numbers/?from_date=${fromDate}&to_date=${toDate}&is_archived=False`,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      )
      .then((response) => {
        return response.data.results;
      });
  }
}

export default PlannerService;
