/* Data actions */
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const LOAD_LANGUAGES = "LOAD_LANGUAGES";

/* User actions */
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const CHANGE_PRESETS = "CHANGE_PRESETS";
export const SET_PROFILE = "SET_PROFILE";
export const SET_TENANT = "SET_TENANT";
export const SET_TENANT_CONNECTIONS = "SET_TENANT_CONNECTIONS";

/* UI */
export const SET_UI_VARS = "SET_UI_VARS";
export const SET_COLORS = "SET_COLORS";
export const SHOW_COMMENTS_DRAWER = "SHOW_COMMENTS_DRAWER";
export const SHOW_COMMENTS_SIDEBAR = "SHOW_COMMENTS_SIDEBAR";
export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL";
export const SHOW_DRAWER_WITH_SLOTS = "SHOW_DRAWER_WITH_SLOTS";
export const TOGGLE_DRAWER_LOADING = "TOGGLE_DRAWER_LOADING";
export const SET_SAVING_PREFERENCES = "SET_SAVING_PREFERENCES";
export const SET_DISABLE_KEY_CALLBACK = "SET_DISABLE_KEY_CALLBACK";
export const TOGGLE_EXPAND_TABLE_ITEMS = "TOGGLE_EXPAND_TABLE_ITEMS";
export const SET_WORKFLOW_DETAIL_RESPONSE = "SET_WORKFLOW_DETAILS_RESPONSE";

/* Theme */
export const INIT_THEME = "INIT_THEME";

/* Projects */
export const SET_SUBMIT_DETAILS = "SET_SUBMIT_DETAILS";
export const SET_IS_SAVING = "SET_IS_SAVING";

/* Planner */
export const SET_CURRENT_DAY = "SET_CURRENT_DAY";
export const SET_HEIGHT_JOB_SECTION = "SET_HEIGHT_JOB_SECTION";
export const SET_WEEK_FILTER = "SET_WEEK_FILTER";
export const SET_SHOW_HOURS_SECTION = "SET_SHOW_HOURS_SECTION";
export const RESET_FILTERS = "RESET_FILTERS";
export const RESET_FILTERS_PARTIAL = "RESET_FILTERS_PARTIAL";
export const SET_LAST_APPLIED_EXTRA_FILTERS = "SET_LAST_APPLIED_EXTRA_FILTERS";
export const SET_SHOW_FILTER = "SET_SHOW_FILTER";
export const SET_PLANNER_CLUSTERS = "SET_PLANNER_CLUSTERS";
/* Planer - Preferences */
export const SET_TEMP_PREFERENCES = "SET_TEMP_PREFERENCES";
export const SET_PREFERENCES_WITH_TEMP = "SET_PREFERENCES_WITH_TEMP";
export const SET_SHOW_CLUSTER_VIEW = "SET_SHOW_CLUSTER_VIEW";
export const SET_INCLUDE_FULL_JOBS = "SET_INCLUDE_FULL_JOBS";
export const SET_AVAILABLE_CANDIDATES = "SET_AVAILABLE_CANDIDATES";
export const SET_SORT_CANDIDATES_BY = "SET_SORT_CANDIDATES_BY";
/* Planner- sidebar */
export const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
export const SET_SELECTED_JOB = "SET_SELECTED_JOB";
export const SET_SELECTED_CLUSTER = "SET_SELECTED_CLUSTER";
export const SET_APPLICATIONS = "SET_APPLICATIONS";
export const SET_INVITATIONS = "SET_INVITATIONS";
export const SET_REGISTERED = "SET_REGISTERED";
export const SET_PROPOSED = "SET_PROPOSED";
export const SET_CANCELED_REGISTERED = "SET_CANCELED_REGISTERED";
export const ADD_CANCELED_REGISTERED = "ADD_CANCELED_REGISTERED";
export const REMOVE_CANCELED_REGISTERED = "REMOVE_CANCELED_REGISTERED";
export const SET_CANCELED_INVITATIONS = "SET_CANCELED_INVITATIONS";
export const ADD_CANCELED_INVITATION = "ADD_CANCELED_INVITATION";
export const REMOVE_CANCELED_INVITATION = "REMOVE_CANCELED_INVITATION";
export const SET_CANCELED_APPLICATIONS = "SET_CANCELED_APPLICATIONS";
export const ADD_CANCELED_APPLICATION = "ADD_CANCELED_APPLICATION";
export const REMOVE_CANCELED_APPLICATION = "REMOVE_CANCELED_APPLICATION";
export const SET_CANCELED_PROPOSED = "SET_CANCELED_PROPOSED";
export const ADD_CANCELED_PROPOSED = "ADD_CANCELED_PROPOSED";
export const REMOVE_CANCELED_PROPOSED = "REMOVE_CANCELED_PROPOSED";
export const ADD_INVITATIONS = "ADD_INVITATIONS";
export const ADD_REGISTERED = "ADD_REGISTERED";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const ADD_PROPOSED = "ADD_PROPOSED";
export const SET_PLANNER_SIDEBAR_LOADING = "SET_PLANNER_SIDEBAR_LOADING";
export const SET_PLANNER_SIDEBAR_RELOAD = "SET_PLANNER_SIDEBAR_RELOAD";
export const SET_APPLIED_FREELANCERS = "SET_APPLIED_FREELANCERS";
export const ADD_APPLIED_FREELANCER = "ADD_APPLIED_FREELANCER";
export const SET_CANCELED_APPLIED_FREELANCERS = "SET_APPLIED_FREELANCERS";
export const ADD_CANCELED_APPLIED_FREELANCER =
  "ADD_CANCELED_APPLIED_FREELANCER";
export const REMOVE_CANCELED_APPLIED_FREELANCER =
  "REMOVE_CANCELED_APPLIED_FREELANCER";

/* Planner - Extensive search */
export const SET_EXTENSIVE_FILTERS = "SET_EXTENSIVE_FILTERS";
/* Planner - Planning */
export const SHOW_PLANNING = "SHOW_PLANNING";
export const SET_DATA_TO_PLANNING = "SET_DATA_TO_PLANNING";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_SELECTED_PLANNING_JOB = "SET_SELECTED_PLANNING_JOB";
export const SET_SELECTED_EMPLOYEES = "SET_SELECTED_EMPLOYEES";
export const SET_SELECTED_PROPOSED = "SET_SELECTED_PROPOSED";
export const SET_SELECTED_FREELANCERS = "SET_SELECTED_FREELANCERS";
export const SET_SELECTED_FULL_EMPLOYEES = "SET_SELECTED_FULL_EMPLOYEES";
export const SET_CURRENT_SORT = "SET_CURRENT_SORT";
export const SET_CLUSTERS_ENABLED = "SET_CLUSTERS_ENABLED";
export const CHECK_IF_DATA = "CHECK_IF_DATA";
/* Planner - New job */
export const SHOW_NEW_JOB = "SHOW_NEW_JOB";
export const SET_NEW_JOB = "SET_NEW_JOB";
export const SET_STEP_ONE_COMPLETED = "SET_STEP_ONE_COMPLETED";

/* ListViews */
export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const SET_FILTERS = "SET_FILTERS";
export const SET_PAGE = "SET_PAGE";
export const SET_SORT_TYPE = "SET_SORT_TYPE";

/* Employee User */
export const LOGIN_EMPLOYEE_USER = "LOGIN_EMPLOYEE_USER";
export const LOGOUT_EMPLOYEE_USER = "LOGOUT_EMPLOYEE_USER";
export const LOAD_EMPLOYEE_USER_DATA = "LOAD_EMPLOYEE_USER_DATA";
export const TOGGLE_EMPLOYEE_LOADING = "TOGGLE_EMPLOYEE_LOADING";
export const COMPLETE_REGISTRY = "COMPLETE_REGISTRY";
export const ADD_TO_DOCUMENTS_LIST = "SET_UPLOAD_DOCUMENTS";
export const SET_UPLOAD_DOCUMENTS_LIST = "SET_UPLOAD_DOCUMENTS_LIST";

/* Salary Run */
export const SET_PERIOD = "SET_PERIOD";
export const SET_SUMMARY = "SET_SUMMARY";
export const SET_INCLUDE_CORRECTIONS = "SET_INCLUDE_CORRECTIONS";
export const SET_RATECARD = "SET_RATECARD";
export const SET_EXPORT_PAYLOAD = "SET_EXPORT_PAYLOAD";

/* Notifications */
export const SET_SELECTED_NOTIFICATION = "SET_SELECTED_NOTIFICATION";
export const SET_NOTIFICATION_LOADING = "SET_NOTIFICATION_LOADING";
export const SET_NOTIFICATION_UNREAD = "SET_NOTIFICATION_UNREAD";

/* DATABASE */
export const SET_DATABASE_TABLE_VALUES = "SET_DATABASE_TABLE_VALUES";
export const SET_DATABASE_TABLE_FILTERS = "SET_DATABASE_TABLE_FILTERS";
export const SET_DATABASE_TABLE_APPLIED_FILTERS =
  "SET_DATABASE_TABLE_APPLIED_FILTERS";
export const SET_DATABASE_STATE = "SET_DATABASE_STATE";

/* DATABASE - SETTINGS PAGE */
export const SET_TABLE_TO_CUSTOMIZE = "SET_TABLE_TO_CUSTOMIZE";
export const SET_SELECTED_COLUMNS = "SET_SELECTED_COLUMNS";

/* POOLS TAB */
export const SET_EMPLOYEES_POOLS_FILTERS = "SET_EMPLOYEES_POOLS_FILTERS";
export const SET_EMPLOYEES_POOLS_APPLIED_FILTERS =
  "SET_EMPLOYEES_POOLS_APPLIED_FILTERS";

/* MEDIA TAB */
export const SET_MEDIA_FILTERS = "SET_MEDIA_FILTERS";
export const SET_MEDIA_APPLIED_FILTERS = "SET_MEDIA_APPLIED_FILTERS";
export const SET_PROJECT_MEDIA = "SET_PROJECT_MEDIA";
export const SET_EMPLOYEE_MEDIA = "SET_EMPLOYEE_MEDIA";
export const RESET_PROJECT_MEDIA = "RESET_PROJECT_MEDIA";
export const RESET_EMPLOYEE_MEDIA = "RESET_EMPLOYEE_MEDIA";
export const SET_PROJECT_FILTERED_DATA = "SET_PROJECT_FILTERED_DATA";

/* Occupations */
export const SET_OCCUPATIONS_SELECTED_JAP = "SET_OCCUPATIONS_SELECTED_JAP";
export const SET_OCCUPATIONS_SHOW_MODAL = "SET_OCCUPATIONS_SHOW_MODAL";

/* Scheduler */
export const SET_SCHEDULER_PREFERENCES = "SET_SCHEDULER_PREFERENCES";

/* Setting */
export const SET_SETTING_TENANT = "SET_SETTING_TENANT";

/* Material List Settings */

export const LIST_OF_MATERIALS = "LIST_OF_MATERIALS";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const REQUEST_ADD_CATEGORIES = "REQUEST_ADD_CATEGORIES";
export const ADD_MATERIAL = "ADD_MATERIAL";
export const ADD_CATEGORIES = "ADD_CATEGORIES";
export const EDIT_MATERIAL = "EDIT_MATERIAL";
export const DELETE_MATERIAL = "DELETE_MATERIAL";


export const GET_IMAGES_URLS_SET_CARDS = "IMAGES_URLS_SET_CARDS";
export const POST_IMAGE_URLS = "POST_IMAGE_URLS";
export const SET_CARDS_LOADING = "SET_CARDS_LOADING";

export const GET_EMPLOYEE_IMAGES = "GET_EMPLOYEE_IMAGES";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const SELECTED_IMAGES = "SELECTED_IMAGES";
export const SET_SELECTED_EMPLOYEE = "SET_SELECTED_EMPLOYEE"
export const SET_CROPPED_AREA = "SET_CROPPED_AREA";
export const SET_IMAGE_SELECTED = "SET_IMAGE_SELECTED";
export const DELETE_SELECTED_IMAGE = "DELETE_SELECTED_IMAGE";
export const SET_FREE_DROPDOWN = "SET_FREE_DROPDOWN";
export const SET_GENERAL_DROPDOWN = "SET_GENERAL_DROPDOWN";
export const SET_CHAR_DROPDOWN = "SET_CHAR_DROPDOWN";
export const SET_DROPDOWN = "SET_DROPDOWN";
export const SET_IMAGE_URL_LIST = "SET_IMAGE_URL_LIST";
export const SET_CROPPING = "SET_CROPPING";
export const SET_ZOOM = "SET_ZOOM";
export const SET_ROTATION = "SET_ROTATION";
export const SET_IMAGE_LOADING = "SET_IMAGE_LOADING";
export const DELETE_IMAGES = "DELETE_IMAGES";

