import { combineReducers } from "redux";
import { SET_SCHEDULER_PREFERENCES, USER_LOGIN } from "redux/types";

import card from "./card";

const sortBy = (state = "-contract_hours_difference", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.scheduler &&
        typeof action.data.preferences.scheduler.sortBy === "string"
        ? action.data.preferences.scheduler.sortBy
        : state;

    case SET_SCHEDULER_PREFERENCES:
      return action.data.sortBy && typeof action.data.sortBy === "string"
        ? action.data.sortBy
        : state;

    default:
      return state;
  }
};

const preferences = combineReducers({
  sortBy,
  card,
});

export default preferences;
