import { DELETE_IMAGES, GET_IMAGES_URLS_SET_CARDS, POST_IMAGE_URLS, SET_CARDS_LOADING } from "redux/types";

const setCards = (
  state = { imageUrls: [], loading: false},
  action = {}
) => {
  switch (action.type) {
    case SET_CARDS_LOADING :
        return {
            ...state,
            loading: true
        }
    case GET_IMAGES_URLS_SET_CARDS:
      return {
        ...state,
        imageUrls: action.data,
        loading:false
       };
    case POST_IMAGE_URLS: 
       return {
        ...state,
        imageUrls: [...state.imageUrls, ...action.data],
        loading: false
       }
    case DELETE_IMAGES: 
       return {
        ...state,
        imageUrls: state.imageUrls.filter(el =>  el.uuid !== action.uuid),
        loading: false
       }
    default:
      return state;
  }
};

export default setCards;