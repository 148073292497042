import axios from "axios";
import { getFiltersString } from "utils/helpers";
import { serverConfig } from "../../config";

const apiUrl = serverConfig.apiUrl;
const marketplaceApiUrl = serverConfig.marketplaceApiUrl;

const employerService = {
  getEmployerInformation(token, tenantUuid) {
    return axios.get(
      `${apiUrl}/api/users/marketplace-tenants-draft/${tenantUuid}/`,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  getTableEmployers(
    token,
    {
      sortKey = "created_at",
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
      isActive = false,
      tenantUuid = false
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/users/marketplace-tenants-draft/?sort=${sortKey}${
        pagination.page && pagination.size
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_active=${isActive ? "True" : "False"}${
        tenantUuid ? `&tenant_admin__uuid=${tenantUuid}` : ""
      }${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${
              exportOptions.columns
            }&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json"
      }
    );
  },
  verifyEmployer: (token, tenantUuid, hash) => {
    return axios.post(
      `${marketplaceApiUrl}/api/users/tenants/verify/${hash}/`,
      {
        tenant_uuid: tenantUuid,
        token: token
      }
    );
  },
  /**
   * @param {string} token
   * @param {string} employerUuid
   * @param {{ [key: string]: any }} employerData
   */
  setEmployer(token, employerUuid, employerData) {
    return axios.put(
      `${apiUrl}/api/commons/tenant/update/${employerUuid}/`,
      employerData,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  }
};

export default employerService;
