import { SET_PROJECT_FILTERED_DATA } from "redux/types";

const projectsDataForPools = (
  state = { projectDataValue: [] },
  action = {}
) => {
  switch (action.type) {
    case SET_PROJECT_FILTERED_DATA:
      return { projectDataValue: action.value };
    default:
      return state;
  }
};

export default projectsDataForPools;
