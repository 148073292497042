import axios from "axios";
import { serverConfig } from "../config";
const apiUrl = serverConfig.apiUrl;

const materiallistService = {
    postCategories: (token, data) => {
        return axios.post(`${apiUrl}/api/jobs/tenant-item-categories/`, data, {
            headers: {
              Authorization: `JWT ${token}`
            },
        })
    },
    postMaterial: (token, data) => {
        return axios.post(`${apiUrl}/api/jobs/tenant-items/`, data, {
            headers: {
                Authorization: `JWT ${token}`,
              }
        })
    },
    editMaterialData: (token, id, data) => {
        return axios.put(`${apiUrl}/api/jobs/tenant-items/${id}/`, data, {
            headers: {
                Authorization: `JWT ${token}`,
              },
        })
    },
    getCategories: (token) => {
        return axios.get(`${apiUrl}/api/jobs/tenant-item-categories/`, {
            headers: {
                Authorization: `JWT ${token}`,
                
            }
        })
    },
    getMaterials: (token, sortKey = "category") => {
        return axios.get(`${apiUrl}/api/jobs/tenant-items/?sort=${sortKey}`, {
            headers: {
                Authorization: `JWT ${token}`
            }
        })
    },
    exportPdf: (token) => {
        return axios.post(`${apiUrl}/api/jobs/job-application-items/export/`, null , {
            headers: {
                Authorization: `JWT ${token}`
            }
        })
    }
}

export default materiallistService;