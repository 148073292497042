import axios from "axios";
import { serverConfig } from "../config";

const apiUrl = serverConfig.apiUrl;

const contactPersonService = {
  getContactPerson: (token, tenantUuid, clientUuid) => {
    return axios.get(
      `${apiUrl}/api/users/app-brand-users/${tenantUuid}/?membership_user__company__uuid=${clientUuid}`,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  createContactPerson: (token, tenantUuid, clientUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/users/company-user/${tenantUuid}/${clientUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  },
  updateContactPerson: (token, contractUuid, payload) => {
    return axios.put(`${apiUrl}/api/users/profile/${contractUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` }
    });
  }
};

export default contactPersonService;
