import { SET_PREFERENCES_WITH_TEMP, USER_LOGIN } from "redux/types";
import { combineReducers } from "redux";

const showClient = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showClient !== "undefined"
        ? action.data.showClient
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showClient !==
          "undefined"
        ? action.data.preferences.planner.jobCard.showClient
        : state;

    default:
      return state;
  }
};

const showProject = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showProject !== "undefined"
        ? action.data.showProject
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showProject !==
          "undefined"
        ? action.data.preferences.planner.jobCard.showProject
        : state;

    default:
      return state;
  }
};

const showJobCategory = (state = true, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showJobCategory !== "undefined"
        ? action.data.showJobCategory
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showJobCategory !==
          "undefined"
        ? action.data.preferences.planner.jobCard.showJobCategory
        : state;

    default:
      return state;
  }
};

const showTime = (state = true, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showTime !== "undefined"
        ? action.data.showTime
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showTime !== "undefined"
        ? action.data.preferences.planner.jobCard.showTime
        : state;

    default:
      return state;
  }
};

const showCity = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showCity !== "undefined"
        ? action.data.showCity
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showCity !== "undefined"
        ? action.data.preferences.planner.jobCard.showCity
        : state;

    default:
      return state;
  }
};

const showStreet = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showStreet !== "undefined"
        ? action.data.showStreet
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showStreet !==
          "undefined"
        ? action.data.preferences.planner.jobCard.showStreet
        : state;

    default:
      return state;
  }
};

const showExtraNote = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return action.data && typeof action.data.showExtraNote !== "undefined"
        ? action.data.showExtraNote
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showExtraNote !==
          "undefined"
        ? action.data.preferences.planner.jobCard.showExtraNote
        : state;

    default:
      return state;
  }
};

const showJobStatus = (state = true, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return action.data && typeof action.data.showJobStatus !== "undefined"
        ? action.data.showJobStatus
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.jobCard.showJobStatus !==
          "undefined"
        ? action.data.preferences.planner.jobCard.showJobStatus
        : state;

    default:
      return state;
  }
};

const jobCard = combineReducers({
  showCity,
  showStreet,
  showClient,
  showJobCategory,
  showExtraNote,
  showJobStatus,
  showProject,
  showTime
});

export default jobCard;
