import axios from "axios";
import { serverConfig } from "../config";

const apiUrl = serverConfig.apiUrl;

const commentsService = {
  getComments: (token, tenantUuid, fkUuid, options = {}) => {
    const { page, limit } = options;

    return axios.get(
      `${apiUrl}/api/comments/${tenantUuid}/?fk_uuid=${fkUuid}&desc_sort=date_created${page ? "&page=" + page : ""
      }${limit ? "&page_size=" + limit : ""}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  bulkCreateComments: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/comments/${tenantUuid}/bulk/`, payload, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    });
  },
  createComment: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/comments/${tenantUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    })
  },
  editingComment: (token, commentUuid, payload) => {
    return axios.put(`${apiUrl}/api/comments/${commentUuid}/update/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    })
  }
};

export default commentsService;
