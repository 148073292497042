import { combineReducers } from "redux";
import {
  SET_TEMP_PREFERENCES,
  SET_PREFERENCES_WITH_TEMP,
  SET_SHOW_CLUSTER_VIEW,
  SET_INCLUDE_FULL_JOBS,
  SET_AVAILABLE_CANDIDATES,
  SET_SORT_CANDIDATES_BY,
  USER_LOGIN
} from "redux/types";

import jobCard from "./jobCard";
import complications from "./complications";

const temporalPreferences = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_TEMP_PREFERENCES:
      if (!action.data) return {};

      return {
        ...state,
        ...action.data
      };

    default:
      return state;
  }
};

const showClusterView = (state = false, action = {}) => {
  switch (action.type) {
    case SET_SHOW_CLUSTER_VIEW:
      return action.data;

    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showClusterView !== "undefined"
        ? action.data.showClusterView
        : state;

    case USER_LOGIN:
      return state; // always show cluster configuration disabled
      // return action.data.preferences.planner &&
      //   typeof action.data.preferences.planner.showClusterView !== "undefined"
      //   ? action.data.preferences.planner.showClusterView
      //   : state;

    default:
      return state;
  }
};

const includeFullJobs = (state = false, action = {}) => {
  switch (action.type) {
    case SET_INCLUDE_FULL_JOBS:
      return action.data;

    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.includeFullJobs !== "undefined"
        ? action.data.includeFullJobs
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.includeFullJobs !== "undefined"
        ? action.data.preferences.planner.includeFullJobs
        : state;

    default:
      return state;
  }
};

const availableCandidates = (state = true, action = {}) => {
  switch (action.type) {
    case SET_AVAILABLE_CANDIDATES:
      return action.data;

    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.availableCandidates !== "undefined"
        ? action.data.availableCandidates
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.availableCandidates !==
          "undefined"
        ? action.data.preferences.planner.availableCandidates
        : state;

    default:
      return state;
  }
};

const sortCandidatesBy = (state = "distance", action = {}) => {
  switch (action.type) {
    case SET_SORT_CANDIDATES_BY:
      return action.data;

    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.sortCandidatesBy !== "undefined"
        ? action.data.sortCandidatesBy
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.sortCandidatesBy !== "undefined"
        ? action.data.preferences.planner.sortCandidatesBy
        : state;

    default:
      return state;
  }
};

const showAllCandidates = (state = true, action = {}) => {
  switch (action.type) {
    case SET_SHOW_CLUSTER_VIEW:
      return action.data;

    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.showAllCandidates !== "undefined"
        ? action.data.showAllCandidates
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        typeof action.data.preferences.planner.showAllCandidates !== "undefined"
        ? action.data.preferences.planner.showAllCandidates
        : state;

    default:
      return state;
  }
};

const preferences = combineReducers({
  temporalPreferences,
  showClusterView,
  includeFullJobs,
  availableCandidates,
  sortCandidatesBy,
  showAllCandidates,
  jobCard,
  complications
});

export default preferences;
