import axios from "axios";
import { serverConfig } from "../config";
import { getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl;

const sharedJobServices = {
  getPinData: (token, pin) => {
    return axios.get(`${apiUrl}/api/shared/pin/details/${pin}/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTenantByPin: (token, pin) => {
    return axios.get(
      `${apiUrl}/api/commons/tenant/list/?invitationpin__pin=${pin}`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  createTenantConnectionPin: (token) => {
    return axios.post(`${apiUrl}/api/shared/connection/`, [], {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  acceptTenantConnection: (token, payload) => {
    return axios.post(`${apiUrl}/api/shared/connection/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  rejectTenantConnection: (token, pinUuid, payload) => {
    return axios.put(`${apiUrl}/api/shared/pin/${pinUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getConnectionBetweenTenants: (token, pinOwnerUuid, currentTenantUuid) => {
    return axios.get(
      `${apiUrl}/api/shared/connection/all/?tenant_1__uuid=${currentTenantUuid}&tenant_2__uuid=${pinOwnerUuid}`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },

  getTenantConnections: (token) => {
    return axios.get(`${apiUrl}/api/shared/connection/all/?is_active=True`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getAllTenantConnections: (token) => {
    return axios.get(`${apiUrl}/api/shared/connection/all/`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateConnection: (token, connectionUuid, isArchived) => {
    return axios.put(
      `${apiUrl}/api/shared/connection/${connectionUuid}/`,
      { is_archived: isArchived },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  shareJobWithTenant: (token, payload) => {
    return axios.post(`${apiUrl}/api/shared/job/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateSharedJob: (token, sharedJobUuid, payload) => {
    return axios.put(`${apiUrl}/api/shared/job/${sharedJobUuid}/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getSharedJobs: (
    token,
    jobUuid = false,
    jobIsArchived = null,
    connectionUuid = false
  ) => {
    return axios.get(
      `${apiUrl}/api/shared/job/all/?${jobUuid ? `job__uuid=${jobUuid}` : ""}${
        connectionUuid ? `&connection__uuid=${connectionUuid}` : ""
      }${jobIsArchived ? `&is_archived=${jobIsArchived}` : ""}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  bulkUpdateSharedJob: (token, payload) => {
    return axios.put(`${apiUrl}/api/shared/job/bulk/update/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  addPoolToSharedJob: (token, payload) => {
    return axios.post(`${apiUrl}/api/shared/custom-filter/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updatePoolToSharedJob: (token, sharedCustomFilterUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/shared/custom-filter/${sharedCustomFilterUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  shareJobBulk: (token, payload) => {
    return axios.post(`${apiUrl}/api/shared/job/bulk/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  getTableJobRequests(
    token,
    tenantUuid,
    {
      sortKey = "start_date",
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
      version = "",
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/shared/request/${tenantUuid}/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}`, version },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  saveShareCombination: (token, payload) => {
    return axios.post(`${apiUrl}/api/shared/combination/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  cancelAllJoApplications: (token, jobUuid) => {
    return axios.put(
      `${apiUrl}/api/jobs/applications/cancel/${jobUuid}/`,
      {},
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTenantConnectionCombinations: (token, connectionUuid) => {
    return axios.get(
      `${apiUrl}/api/shared/combination/?connection__uuid=${connectionUuid}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateCombination: (token, combinationUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/shared/combination/${combinationUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  deleteCombination: (token, combinationUuid) => {
    return axios.delete(
      `${apiUrl}/api/shared/combination/${combinationUuid}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
};

export default sharedJobServices;
