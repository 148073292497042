import axios from "axios";
import { getFiltersString } from "utils/helpers";
import { serverConfig } from "../../config";

const apiUrl = serverConfig.apiUrl;
const marketplaceApiUrl = serverConfig.marketplaceApiUrl;

const freelancerService = {
  getTableFreelancers(
    token,
    tenantUuid = null,
    {
      sortKey = "first_name",
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
      isArchive = null,
      isActive = null,
    }
  ) {
    const filtersString = !!filters ? getFiltersString(filters) : "";

    return axios.get(
      `${apiUrl}/api/users/marketplace-employees/?${
        sortKey ? `sort=${sortKey}` : ""
      }${
        pagination.page && pagination.size
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }${
        null !== isActive
          ? isActive
            ? "&is_active=True"
            : "&is_active=False"
          : ""
      }${tenantUuid ? `&tenant_admin__uuid=${tenantUuid}` : ""}${
        null !== isArchive
          ? isArchive
            ? "&is_archived=True"
            : "&is_archived=False"
          : ""
      }${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  getFreelancerInfo(token, freelancerUuid) {
    return axios.get(
      `${apiUrl}/api/users/marketplace-employees/${freelancerUuid}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  verifyFreelancer: (token, tenantUuid, freelancerUuid) => {
    return axios.put(
      `${marketplaceApiUrl}/api/users/employees/verify/${freelancerUuid}/`,
      {
        tenant_uuid: tenantUuid,
        token: token,
      }
    );
  },
  validateExperience: (token, tenantUuid, experienceUuid, isCorrect) => {
    return axios.put(
      `${marketplaceApiUrl}/api/experiences/verify/${experienceUuid}/?tenant_uuid=${tenantUuid}&token=${token}`,
      { is_correct: isCorrect }
    );
  },
  getFreelancersInJob: (token, jobUuid, jobApplicationStatus = null) => {
    return axios.get(
      `${apiUrl}/api/jobs/marketplace/job-application/employees/${jobUuid}/${
        jobApplicationStatus
          ? `?active_status__status_type=${jobApplicationStatus}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  acceptFreelancerApplication: (token, tenantUuid, japUuid) => {
    return axios.put(
      `${marketplaceApiUrl}/api/jobs/job-application/tenant/${japUuid}/accept/?tenant_uuid=${tenantUuid}&token=${token}`,
      {}
    );
  },
  cancelFreelancerApplication: (token, tenantUuid, japUuid, payload) => {
    return axios.put(
      `${marketplaceApiUrl}/api/jobs/job-application/tenant/${japUuid}/cancel/?tenant_uuid=${tenantUuid}&token=${token}`,
      payload
    );
  },
};

export default freelancerService;
