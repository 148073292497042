import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

/* Default meta data */
import {
  title as defaultTitle,
  link as defaultLink,
  meta as defaultMeta
} from "./assets";

const Page = ({ title, link, meta, children }) => {
  return (
    <Fragment>
      <Helmet
        title={title || defaultTitle}
        link={link || defaultLink}
        meta={meta || defaultMeta}
      />
      {children}
    </Fragment>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  link: PropTypes.array,
  meta: PropTypes.array
};

export default Page;
