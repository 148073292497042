/* Based on the template in Web Starter Kit :
https://github.com/google/web-starter-kit/blob/master/app/index.html
// */
import { isProduction, serverConfig } from "../../config/";

const metaAssets = () => {
  return [
    { charset: "utf-8" },
    // Setting IE=edge tells Internet Explorer to use the latest engine to render the page and execute Javascript
    { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
    //  Meta descriptions are commonly used on search engine result pages to display preview snippets for a given page.
    {
      name: "description",
      content: "Fleks, de ultieme oplossing in de HR branche voor flexkrachten",
    },
    // Mobile Safari introduced this tag to let web developers control the viewport's size and scale
    // The width property controls the size of the viewport, the initial-scale property controls
    // the zoom level when the page is first loaded
    { name: "viewport", content: "user-scalable = no, initial-scale=1" },
    // Add to homescreen for Chrome on Android
    { name: "mobile-web-app-capable", content: "yes" },
    // Add to homescreen for Safari on IOS
    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "apple-mobile-web-app-status-bar-style", content: "black" },
    { name: "apple-mobile-web-app-title", content: "Title" },
    // Tile icon for Win8 (144x144 + tile color)
    // { name: 'msapplication-TileImage', content: mscon },
    // { name: 'msapplication-TileColor', content: '#3372DF' }
  ];
};
const linkAssets = () => {
  const links = [
    // SEO: If your mobile URL is different from the desktop URL,
    // add a canonical link to the desktop page https://developers.google.com/webmasters/smartphone-sites/feature-phones
    { rel: "canonical", href: serverConfig.site },
  ];
  return isProduction ? links : links.filter((l) => l.rel !== "stylesheet");
};

export const title = "Dashboard | Fleks Works";
export const meta = metaAssets();
export const link = linkAssets();
