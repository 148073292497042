import axios from "axios";
import { serverConfig } from "../config";
import { correctResults, getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl;

const clientService = {
  getCompanyData: (
    token,
    tenantUuid,
    key,
    limit,
    page,
    filtersString,
    isArchive = "False",
    sortType = "sort",
    { includeDepartments = false } = {}
  ) => {
    filtersString =
      typeof filtersString === "object"
        ? getFiltersString(filtersString)
        : filtersString;

    return axios.get(
      `${apiUrl}/api/users/company/${tenantUuid}/?${sortType}=${key}&is_archived=${isArchive}${
        limit ? `&page_size=${limit}` : ""
      }${page ? `&page=${page}` : ""}${filtersString ? filtersString : ""}`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  createCompany: (token, tenantUuid, payload) => {
    return axios.post(`${apiUrl}/api/users/company/${tenantUuid}/`, payload, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  setArchiveStatusCompany: (token, tenantUuid, data, action) => {
    return Promise.all(
      data.map((item) => {
        return axios.put(
          `${apiUrl}/api/users/company/${tenantUuid}/${item.uuid}/`,
          {
            is_archived: action === "archive" ? "True" : "False",
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );
      })
    ).then((results) => correctResults(results));
  },
  getProfile: (token, tenantUuid, clientUuid) => {
    return axios.get(
      `${apiUrl}/api/users/company/${tenantUuid}/${clientUuid}/`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  setClient: (token, tenantUuid, clientUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/users/company/${tenantUuid}/${clientUuid}/`,
      payload,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  getChildren: (token, tenantUuid, parentUuid, filtersString) => {
    return axios.get(
      `${apiUrl}/api/users/company/${tenantUuid}/?company__uuid=${parentUuid}`,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  getSingleClient: (token, tenantUuid, companyUuid) => {
    return axios
      .get(
        `${apiUrl}/api/users/company/${tenantUuid}/?company__isnull=True&uuid=${companyUuid}`,
        { headers: { Authorization: `JWT ${token}` } }
      )
      .then((response) => ({
        ...response,
        data: { ...response.data, results: response.data.results[0] },
      }));
  },
  getTemplateProjects: (token, tenantUuid, clientUuid, options = {}) => {
    const { filters = {} } = options;
    const stringFilters =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios.get(
      `${apiUrl}/api/jobs/templates/${tenantUuid}/${clientUuid}/?is_archived=False${stringFilters}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createTemplateProjects: (token, tenantUuid, clientUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/jobs/templates/${tenantUuid}/${clientUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  deleteTemplateProjects: (token, templateUuid) => {
    return axios.put(
      `${apiUrl}/api/jobs/templates/update/${templateUuid}/`,
      { is_archived: "True" },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  editTemplateProjects: (token, templateUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/templates/update/${templateUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getStandardJobs: (token, tenantUuid, projectTemplateUuid, options = {}) => {
    return axios.get(
      `${apiUrl}/api/jobs/templates/job/${tenantUuid}/${projectTemplateUuid}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  createStandardJobs: (token, tenantUuid, projectTemplateUuid, data) => {
    return axios.post(
      `${apiUrl}/api/jobs/templates/job/${tenantUuid}/${projectTemplateUuid}/`,
      data,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  deleteStandardJobs: (token, standardJobUuid) => {
    return axios.put(
      `${apiUrl}/api/jobs/templates/update/${standardJobUuid}/`,
      { is_archived: "True" },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTableClients(
    token,
    {
      sortKey = "name",
      isArchive = false,
      pagination = { page: 1, size: 25 },
      filters = {},
      exportOptions = null,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/users/companies/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },

  getAllActiveClients(token) {
    return axios.get(
      `${apiUrl}/api/users/companies/?sort=${"name"}&page=${1}&page_size=${1000}&is_archived=False`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
};

export default clientService;
