import axios from "axios";
import { serverConfig } from "../config";
const apiUrl = serverConfig.apiUrl;

const mailService = {
  sendMail: (token, subject, content, recipients) => {
    return axios.post(
      `${apiUrl}/api/emails/send-email/`,
      { subject, content, recipients },
      {
        headers: { Authorization: `JWT ${token}` }
      }
    );
  }
};

export default mailService;
