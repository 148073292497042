export default function getFiltersStructure(
  intl,
  {
    minContractHours,
    maxContractHours,
    minZipCode,
    maxZipCode,
    pools,
    category,
    fullName,
    freeFields,
    isAvailableOn,
    isAvailableOption,
  }
) {
  return {
    minContractHours: {
      prefix: "employee_contract_employee__",
      key: "contract_hours",
      suffix: "__gte",
      value: minContractHours,
      name: intl.formatMessage({
        id: "Contract.minContractHours",
      }),
    },
    maxContractHours: {
      prefix: "employee_contract_employee__",
      key: "contract_hours",
      suffix: "__lte",
      value: maxContractHours,
      name: intl.formatMessage({
        id: "Contract.maxContractHours",
      }),
    },
    minZipCode: {
      prefix: "",
      key: "post_code",
      suffix: "__gte",
      value: minZipCode,
      name: intl.formatMessage({
        id: "Jobs.zipCodeMin",
      }),
    },
    maxZipCode: {
      prefix: "",
      key: "post_code",
      suffix: "__lte",
      value: maxZipCode,
      name: intl.formatMessage({
        id: "Jobs.zipCodeMax",
      }),
    },
    pools: {
      prefix: "",
      key: "custom_filters__uuid",
      suffix: "__and",
      value: pools,
      name: intl.formatMessage({
        id: "Projects.Details.pool",
      }),
    },
    category: {
      prefix: "user_category__",
      key: "job_category__uuid",
      suffix: "__and",
      value: category,
      name: intl.formatMessage({
        id: "Projects.category",
      }),
    },
    fullName: {
      prefix: "",
      key: "full_name",
      suffix: "__icontains",
      value: fullName,
      name: intl.formatMessage({
        id: "Employees.fullName",
      }),
    },
    freeFields: {
      prefix: "",
      key: "extra_fields",
      suffix: "__value__iin",
      value: freeFields,
      name: intl.formatMessage({ id: "Settings.freeFields" }),
    },
    isAvailableOn: {
      prefix: "",
      key: "availability",
      suffix: "_dates",
      value: isAvailableOn,
      name: intl.formatMessage({
        id: "Scheduler.filters.isAvailableOn",
      }),
    },
    isAvailableOption: {
      prefix: "",
      key: "availability",
      suffix: "_connector",
      value: isAvailableOption,
      name: "",
    },
  };
}
