import { combineReducers } from "redux";
import moment from "moment";
import preferences from "./preferences";
import sidebar from "./sidebar";
import extensiveSearch from "./extensiveSearch";
import planning from "./planning";
import newJob from "./newJob";
import {
  SET_CURRENT_DAY,
  SET_HEIGHT_JOB_SECTION,
  SET_WEEK_FILTER,
  SET_SHOW_HOURS_SECTION,
  RESET_FILTERS,
  RESET_FILTERS_PARTIAL,
  SET_LAST_APPLIED_EXTRA_FILTERS,
  SET_SHOW_CLUSTER_VIEW,
  SET_SHOW_FILTER,
  SET_PLANNER_CLUSTERS,
  CHECK_IF_DATA
} from "redux/types";
const INITIAL_FILTERS = {
  city: "",
  client: "",
  project: "",
  projectManager: "",
  week: moment(),
  isFull: "False",
  cluster: ""
};

const INITIAL_LAST_APPLIED_EXTRA_FILTERS = {
  fullName: {
    key: "full_name",
    value: "",
    suffix: "__icontains",
    prefix: ""
  },
  newEmployees: {
    key: "first_jap_start_date",
    value: "",
    suffix: "__isnull",
    prefix: ""
  },
  jobPermission: {
    key: "job_permissions",
    value: "True",
    suffix: "",
    prefix: ""
  }
};

const currentDay = (state = moment(), action = {}) => {
  switch (action.type) {
    case SET_CURRENT_DAY:
      return action.day;

    default:
      return state;
  }
};

const jobSectionSize = (state = { width: "100%", height: 0 }, action = {}) => {
  switch (action.type) {
    case SET_HEIGHT_JOB_SECTION:
      return {
        ...state,
        height: action.data.height
      };

    default:
      return state;
  }
};

const checkData = (state = {checkifData : false}, action={}) => {
  switch(action.type) {
    case CHECK_IF_DATA: 
      return {
        ...state,
        checkifData: action.data
      }
    default:
      return state;
  }
}

const showFilters = (state = false, action = {}) => {
  switch (action.type) {
    case SET_SHOW_FILTER:
      return action.data;

    default:
      return state;
  }
};

const filters = (state = INITIAL_FILTERS, action) => {
  switch (action.type) {
    case SET_WEEK_FILTER:
      if (!action.data) return INITIAL_FILTERS;

      return {
        ...state,
        ...action.data
      };

    case RESET_FILTERS:
      return INITIAL_FILTERS;

    case RESET_FILTERS_PARTIAL:
      const newState = { ...state };

      action.data.forEach(filterName => {
        newState[filterName] = INITIAL_FILTERS[filterName];
      });

      return newState;

    default:
      return state;
  }
};

const showHoursSection = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_HOURS_SECTION:
      return action.data;

    default:
      return state;
  }
};

const lastAppliedExtraFilters = (
  state = INITIAL_LAST_APPLIED_EXTRA_FILTERS,
  action
) => {
  switch (action.type) {
    case SET_LAST_APPLIED_EXTRA_FILTERS:
      return action.data;

    default:
      return state;
  }
};

const showClusterView = (state = false, action = {}) => {
  switch (action.type) {
    case SET_SHOW_CLUSTER_VIEW:
      return action.data;

    default:
      return state;
  }
};

const clusters = (state = [], action = {}) => {
  switch (action.type) {
    case SET_PLANNER_CLUSTERS:
      return action.data;

    default:
      return state;
  }
};

const planner = combineReducers({
  jobSectionSize,
  currentDay,
  filters,
  preferences,
  showHoursSection,
  lastAppliedExtraFilters,
  showClusterView,
  showFilters,
  sidebar,
  extensiveSearch,
  clusters,
  planning,
  newJob,
  checkData
});

export default planner;
