import axios from "axios";
import FileSaver from "file-saver";
import { serverConfig } from "../../config";
import { correctResults, getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl;

const shiftService = {
  getTableShifts(
    token,
    {
      sortKey = "start_date",
      pagination = { page: 1, size: 25 },
      isArchive = false,
      filters = {},
      exportOptions = null,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/marketplace-applications/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${
              exportOptions.columns
            }&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
};

export default shiftService;
