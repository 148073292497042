import React from "react";

export default {
  sideMenu: {
    standard: {
      dashboard: {
        title: "Sidebar.dashboard",
        icon: <i className="fas fa-tachometer-alt" />,
      },
      planner: {
        title: "Sidebar.planner",
        icon: <i className="fas fa-calendar-alt" />,
      },
      scheduler: {
        title: "Sidebar.scheduler",
        icon: <i className="fas fa-calendar-week" />,
      },
      database: {
        title: "Sidebar.database",
        icon: <i className="fas fa-database" />,
        options: {
          "database/clients": {
            title: "Sidebar.clients",
            icon: <i className="fas fa-address-card" />,
            className: "sub-menu-item",
          },
          "database/projects": {
            title: "Sidebar.projects",
            icon: <i className="fas fa-briefcase" />,
            className: "sub-menu-item",
          },
          /* "database/job-requests": {
            title: "Sidebar.jobRequests",
            icon: <i className="fas fa-map-marker-alt" />,
            className: "sub-menu-item",
          }, */
          "database/jobs": {
            title: "Sidebar.jobs",
            icon: <i className="fas fa-map-marker-alt" />,
            className: "sub-menu-item",
          },
          "database/shifts": {
            title: "Sidebar.shifts",
            icon: <i className="fas fa-calendar-plus" />,
            className: "sub-menu-item",
          },
          "database/employees": {
            title: "Sidebar.employees",
            icon: <i className="fas fa-users" />,
            className: "sub-menu-item",
          },
          "database/applicants": {
            title: "Sidebar.applicants",
            icon: <i className="fas fa-user-plus" />,
            className: "sub-menu-item",
          },
        },
      },
      hours: {
        title: "Sidebar.hours",
        icon: <i className="fas fa-money-bill-alt" />,
        options: {
          "hours/singles": {
            title: "Sidebar.internals",
            icon: <i className="far fa-money-bill-alt" />,
            className: "sub-menu-item",
          },
          /* "hours/totals": {
            title: "Sidebar.totals",
            icon: <i className="fas fa-money-check-alt" />,
            className: "sub-menu-item"
          }, */
          "hours/all-hours": {
            title: "Sidebar.allHours",
            icon: <i className="fas fa-hand-holding-usd" />,
            className: "sub-menu-item",
          }/*,
          "hours/invoices": {
            title: "Sidebar.invoices",
            icon: <i className="far fa-money-bill-alt" />,
            className: "sub-menu-item",
          } /* ,
          "hours/externals": {
            title: "Hours.externals",
            icon: <i className="fas fa-money-check-alt" />,
            className: "sub-menu-item",
          }, */,
        },
      },
      archive: {
        title: "Sidebar.archive",
        icon: <i className="fas fa-archive" />,
      },
    },
    marketplace_admin: {
      dashboard: {
        title: "Sidebar.dashboard",
        icon: <i className="fas fa-tachometer-alt" />,
      },
      planner: {
        title: "Sidebar.planner",
        icon: <i className="fas fa-calendar-alt" />,
      },
      database: {
        title: "Sidebar.database",
        icon: <i className="fas fa-database" />,
        options: {
          "database/new/new-employers": {
            title: "Sidebar.newEmployers",
            icon: <i className="fas fa-users" />,
            className: "sub-menu-item",
          },
          "database/new/employers": {
            title: "Sidebar.employers",
            icon: <i className="fas fa-users" />,
            className: "sub-menu-item",
          },
          "database/new/new-freelancers": {
            title: "Sidebar.newFreelancers",
            icon: <i className="fas fa-user-plus" />,
            className: "sub-menu-item",
          },
          "database/new/freelancers": {
            title: "Sidebar.freelancers",
            icon: <i className="fas fa-user" />,
            className: "sub-menu-item",
          },
          // "database/job-requests": {
          //   title: "Sidebar.jobRequests",
          //   icon: <i className="fas fa-map-marker-alt" />,
          //   className: "sub-menu-item",
          // },
          "database/jobs": {
            title: "Sidebar.jobs",
            icon: <i className="fas fa-map-marker-alt" />,
            className: "sub-menu-item",
          },
          "database/shifts": {
            title: "Sidebar.shifts",
            icon: <i className="fas fa-calendar-plus" />,
            className: "sub-menu-item",
          },
        },
      },
      hours: {
        title: "Sidebar.hours",
        icon: <i className="fas fa-money-bill-alt" />,
        options: {
          "hours/invoices": {
            title: "Sidebar.invoices",
            icon: <i className="far fa-money-bill-alt" />,
            className: "sub-menu-item",
          },
        },
      },
      "archive/jobs": {
        title: "Sidebar.archive",
        icon: <i className="fas fa-archive" />,
      },
    },
    marketplace_client: {
      dashboard: {
        title: "Sidebar.dashboard",
        icon: <i className="fas fa-tachometer-alt" />,
      },
      planner: {
        title: "Sidebar.planner",
        icon: <i className="fas fa-calendar-alt" />,
      },
      database: {
        title: "Sidebar.database",
        icon: <i className="fas fa-database" />,
        options: {
          "database/projects": {
            title: "Sidebar.projects",
            icon: <i className="fas fa-briefcase" />,
            className: "sub-menu-item",
          },
          "database/jobs": {
            title: "Sidebar.jobs",
            icon: <i className="fas fa-map-marker-alt" />,
            className: "sub-menu-item",
          },
          "database/employees": {
            title: "Sidebar.employees",
            icon: <i className="fas fa-users" />,
            className: "sub-menu-item",
          },
          "database/shifts": {
            title: "Sidebar.shifts",
            icon: <i className="fas fa-calendar-plus" />,
            className: "sub-menu-item",
          },
        },
      },
      hours: {
        title: "Sidebar.hours",
        icon: <i className="fas fa-money-bill-alt" />,
        options: {
          "hours/invoices": {
            title: "Sidebar.invoices",
            icon: <i className="far fa-money-bill-alt" />,
            className: "sub-menu-item",
          },
        },
      },
      "archive/jobs": {
        title: "Sidebar.archive",
        icon: <i className="fas fa-archive" />,
      },
    },
    new_type_of_user: {
      // dashboard: {
      //   title: "Sidebar.dashboard",
      //   icon: <i className="fas fa-tachometer-alt" />,
      // },
      planner: {
        title: "Sidebar.planner",
        icon: <i className="fas fa-calendar-alt" />,
      },
      database: {
        title: "Sidebar.database",
        icon: <i className="fas fa-database" />,
        options: {
          "database/projects": {
            title: "Sidebar.projects",
            icon: <i className="fas fa-briefcase" />,
            className: "sub-menu-item",
          },
          /* "database/job-requests": {
            title: "Sidebar.jobRequests",
            icon: <i className="fas fa-map-marker-alt" />,
            className: "sub-menu-item",
          }, */
          "database/jobs": {
            title: "Sidebar.jobs",
            icon: <i className="fas fa-map-marker-alt" />,
            className: "sub-menu-item",
          },
          "database/shifts": {
            title: "Sidebar.shifts",
            icon: <i className="fas fa-calendar-plus" />,
            className: "sub-menu-item",
          }
        },
      },
      hours: {
        title: "Sidebar.hours",
        icon: <i className="fas fa-money-bill-alt" />,
        options: {
          "hours/singles": {
            title: "Sidebar.internals",
            icon: <i className="far fa-money-bill-alt" />,
            className: "sub-menu-item",
          },
          /* "hours/totals": {
            title: "Sidebar.totals",
            icon: <i className="fas fa-money-check-alt" />,
            className: "sub-menu-item"
          }, */
          // "hours/all-hours": {
          //   title: "Sidebar.allHours",
          //   icon: <i className="fas fa-hand-holding-usd" />,
          //   className: "sub-menu-item",
          // },
          "hours/invoices": {
            title: "Sidebar.invoices",
            icon: <i className="far fa-money-bill-alt" />,
            className: "sub-menu-item",
          } /* ,
          "hours/externals": {
            title: "Hours.externals",
            icon: <i className="fas fa-money-check-alt" />,
            className: "sub-menu-item",
          }, */,
        },
      }
    }
  },
};
