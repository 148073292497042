import {
  SET_OCCUPATIONS_SELECTED_JAP,
  SET_OCCUPATIONS_SHOW_MODAL,
} from "../types";
import { combineReducers } from "redux";

const selectedJAP = (state = null, action = {}) => {
  switch (action.type) {
    case SET_OCCUPATIONS_SELECTED_JAP:
      return action.data.selected;

    default:
      return state;
  }
};

const updatedJAP = (state = null, action = {}) => {
  switch (action.type) {
    case SET_OCCUPATIONS_SELECTED_JAP:
      return action.data.updated;

    default:
      return state;
  }
};

const showWorkflowModal = (state = false, action = {}) => {
  switch (action.type) {
    case SET_OCCUPATIONS_SHOW_MODAL:
      return action.data;

    default:
      return state;
  }
};

export default combineReducers({
  selectedJAP,
  updatedJAP,
  showWorkflowModal,
});
