import {
  SET_MEDIA_FILTERS,
  SET_MEDIA_APPLIED_FILTERS,
  SET_PROJECT_MEDIA,
  RESET_PROJECT_MEDIA,
} from "redux/types";
import { combineReducers } from "redux";
import { CodeSandboxCircleFilled } from "@ant-design/icons";

const filters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_MEDIA_FILTERS:
      return action.data.context === "projects"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_MEDIA_APPLIED_FILTERS:
      return action.data.context === "projects"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_MEDIA_APPLIED_FILTERS:
      return action.data.context === "projects"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const projectMedia = (
  state = {
    countMediaImage: 0,
    media: [],
    hasMore: false,
    page: 0,
  },
  action = {}
) => {
  switch (action.type) {
    case SET_PROJECT_MEDIA:
      const media = {
        countMediaImage: state.countMediaImage + action.data.countMediaImage,
        media: state.media.concat(action.data.media),
        hasMore: action.data.hasMore,
        page: state.page + action.data.page,
      };
      return media;
    case RESET_PROJECT_MEDIA:
      return action.data;
    default:
      return state;
  }
};

export const projects = combineReducers({
  filters,
  appliedFilters,
  projectMedia,
});

export default projects;
