import React from "react";
/* Ant Design */
import { Row } from "antd";

/* Styles */
if (typeof window === "object") {
  require("./style.scss");
}

function Layout({ children }) {
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="page-404-layout"
    >
      {children}
    </Row>
  );
}

export default Layout;
